<template>
  <span
    v-if="tabs.length"
    class="-mt-1 -mb-1 leading-0">
    <router-link
      class="p-2 inline-block rounded focus:outline-none hover:bg-gray-100 text-gray-500"
      active-class="bg-gray-200 text-green-500"
      v-for="sub in tabs"
      :key="sub.route.name"
      :to="sub.route">
      <component
        class="h-5"
        :is="sub.icon" />
    </router-link>
  </span>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
};
</script>
