<template>
  <y-card v-if="isLoggedIn">

    <y-card-title>
      <span>
        <span class="text-gray-400">Bonjour</span>
        {{ getUser.name.split(' ')?.[0] }}
      </span>
      <y-card-tabs :tabs="getTabs" />
    </y-card-title>

    <div class="relative overflow-hidden">
      <router-view v-slot="{ Component }">
        <transition
          enter-from-class="translate-x-12 opacity-0"
          leave-active-class="absolute w-full top-0"
          leave-to-class="-translate-x-12 opacity-0">
          <div
            class="transform transition-all duration-300"
            :key="$route.fullPath">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
    </div>

  </y-card>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import YCard from '@/UI/YCard.vue';
import YCardTitle from '@/UI/YCardTitle.vue';
import YCardTabs from '@/UI/YCardTabs.vue';

import YIconClipboardList from '@/UI/icons/YIconClipboardList.vue';
import YIconCog from '@/UI/icons/YIconCog.vue';

export default {

  components: {
    YCard,
    YCardTitle,
    YCardTabs,
  },

  setup() {
    const store = useStore();

    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const getUser = computed(() => store.getters.getUser);

    const getTabs = computed(() => [
      {
        icon: YIconClipboardList,
        route: { name: 'UserEvents' },
      },
      {
        icon: YIconCog,
        route: { name: 'UserSettings' },
      },
    ]);

    return {
      isLoggedIn,
      getUser,
      getTabs,
    };
  },

};
</script>
